import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['element', 'radio'];
  static values = { selected: String };

  connect() {
    setTimeout(() => {
      this.toggle();
    }, 1);
  }

  toggle(e) {
    const radioButton = this.radioTargets.find((radio) => radio.checked);

    if (radioButton) {
      this.elementTargets.forEach((element) => {
        if (radioButton.value == element.dataset.namedToggleElementId) {
          element.style.display = 'flex';
        } else {
          element.style.display = 'none';
        }
      });
    }
  }
}
