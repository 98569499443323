import { Controller } from '@hotwired/stimulus';
import { useDebounce } from 'stimulus-use';

export default class extends Controller {
  static targets = ['search'];
  static debounces = ['submit'];

  connect() {
    useDebounce(this, { wait: 300 });
    if (this.hasSearchTarget) {
      setTimeout(() => {
        this.searchTarget.hasAttribute('autofocus') &&
          this.searchTarget.focus();
      }, 100);
    }
  }

  submit() {
    this.element.requestSubmit();
  }

  clear() {
    Turbo.visit(this.element.action);
  }
}
