import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  copy() {
    navigator.clipboard.writeText(
      this.buttonTarget.querySelector('rml-content').getAttribute('text')
    );

    const icon = this.element.querySelector('rml-icon');
    if (icon) {
      icon.setAttribute('name', 'check');
      setTimeout(() => {
        icon.setAttribute('name', 'files');
      }, 500);
    }
  }
}
