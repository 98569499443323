import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['hideable', 'radio'];
  static values = { showIf: String };

  connect() {
    setTimeout(() => {
      this.toggle();
    }, 1);
  }

  toggle() {
    const radioButton = this.radioTargets.find((radio) => radio.checked);

    if (radioButton) {
      if (radioButton.value != this.showIfValue) {
        // this.hideableTarget.style.display = 'none';
        this.hideableTargets.forEach((hideable) => {
          hideable.style.display = 'none';
        });
      } else {
        // this.hideableTarget.style.display = 'flex';
        this.hideableTargets.forEach((hideable) => {
          hideable.style.display = 'flex';
        });
      }
    }
  }
}
